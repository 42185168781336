/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { Button, Col, Form, Input, message, Modal, Row, Select, Space } from "antd"
import { useNavigate } from "react-router-dom"
import { useSetState } from "ahooks"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import columnsP from "./columns"
import schema from "./schema"
import SKTable from "@/components/SkTable"
import useTableTitle from "@/hooks/useTableTitle"
import { couponEnm } from "../constant"
import { getInvoiceCdkAdd, getInvoiceCdkList } from "../promises"

const OpenTask = () => {
  const nav = useNavigate()
  const [form] = Form.useForm()
  const tableRef: any = useRef(null)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const { setTotal, tableTitle } = useTableTitle("优惠券发放列表")

  const columns: any = [
    ...columnsP,
    // {
    //   title: "操作",
    //   dataIndex: "operation",
    //   fixed: "right",
    // },
  ]

  const handleRequestData = async (params) => {
    const { current, pageSize, used, code, ...rest } = params
    const result: any = await getInvoiceCdkList({
      used: used,
      code: code,
      size: pageSize,
      current,
      ...rest,
    })
    if (result?.code == 200) {
      setTotal(result?.data.total)
    }
    return {
      data: result?.data?.records,
      total: result?.data.total,
    }
  }
  const onFinish = async (values: any) => {
    const result: any = await getInvoiceCdkAdd(values)
    if (result.code == 200) {
      message.success("成功")
      handleCancel()
      upDataTable()
    }
  }
  const handleCancel = () => {
    form.resetFields()
    setIsModalOpen(false)
  }
  const upDataTable = () => {
    tableRef.current.upData()
  }
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>优惠券发放</span>
          </Space>
        }
        breadCrumb={[
          { label: "税票系统", path: "" },
          { label: "优惠券发放", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <SKTable
          ref={tableRef}
          toolbarRender={
            <>
              <Space align="end">
                <Button type="primary" onClick={() => setIsModalOpen(true)}>
                  生成优惠券
                </Button>
              </Space>
            </>
          }
          title={tableTitle}
          pagination={{
            pageSize: 10,
            showQuickJumper: true,
          }}
          // rowSelection={rowSelection}
          request={handleRequestData}
          pageChangeWithRequest={true}
          columns={columns}
          searchFlag={true}
          search={{
            mode: "simple",
            schema: schema,
          }}
        ></SKTable>
      </DetailPageContent>
      <Modal
        open={isModalOpen}
        title="生成优惠券"
        onCancel={handleCancel}
        centered
        width={600}
        footer={null}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          onFinish={onFinish}
        >
          <Row style={{ paddingTop: 20 }}>
            <Col span={20}>
              <Form.Item
                label="生成数量"
                name="quantity"
                rules={[{ required: true, message: "请输入" }]}
              >
                <Input placeholder="请输入" max={50} />
              </Form.Item>
            </Col>
            <Col span={20} style={{ paddingTop: 4, marginBottom: 20 }}>
              <Form.Item label="类型" name="type" rules={[{ required: true, message: "请选择" }]}>
                <Select placeholder="请选择" options={couponEnm}></Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item wrapperCol={{ span: 16 }} style={{ borderTop: "1px solid #f0f0f0" }}>
            <div
              className="form_footer"
              style={{ display: "flex", marginBottom: 10, paddingTop: 10 }}
            >
              <div style={{ display: "flex", flex: 1, textAlign: "right", marginLeft: "110%" }}>
                <Button
                  style={{ marginRight: "12px" }}
                  onClick={(e) => {
                    handleCancel()
                  }}
                >
                  取消
                </Button>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </DetailPageContainer>
  )
}

export default OpenTask
