import _ from "lodash"
import React from "react"
import { Badge } from "antd"
import { couponEnmList } from "../constant"

export const taskStatusNameMap: any = {
  false: {
    status: "warning",
    text: "未使用",
  },
  true: {
    status: "success",
    text: "已使用",
  },
}
export default [
  {
    title: "优惠券码",
    dataIndex: "code",
  },
  {
    title: "可用范围",
    dataIndex: "type",
    render: (_) => {
      return _ ? couponEnmList[_] : "-"
    },
  },
  {
    title: "状态",
    dataIndex: "used",
    render: (value: string, item: any) => {
      return <Badge {...taskStatusNameMap[value]} />
    },
  },
  {
    title: "生成时间",
    dataIndex: "createTime",
  },
  {
    title: "使用时间",
    dataIndex: "usedTime",
  },
  {
    title: "使用用户",
    dataIndex: "usedUserName",
  },
]
