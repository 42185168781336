/* eslint-disable prettier/prettier */
// 查询对象

// 导航
export const breads = [
  {
    label: "代办业务",
    path: "/business",
  },
]
// 开票渠道
export enum ISSUE_CHANNEL {
  BW = "百望云",
  HX = "航信",
  MANUAL = "电子税务局",
  CLIENT_HANDLE = "客户自开",
}
// 开票周期
export enum TAX_FILLING_PERIOD {
  MONTH = "月",
  QUARTER = "季",
}
export enum TAX_MAKE_PERIOD {
  YES = "是",
  NO = "否",
}
export const queryData = [
  {
    label: "创建时间",
    dataIndex: "createTime",
    type: "DatePickers",
    params: {},
  },
  {
    label: "额度更新时间",
    dataIndex: "lastUpdateDate",
    type: "DatePickers",
    params: {},
  },
  {
    label: "办税人",
    dataIndex: "taxClerk",
    type: "Input",
    params: {
      placeholder: "请输入",
    },
  },
  {
    label: "反向开票",
    dataIndex: "reverseInvoicing",
    type: "Select",
    params: {
      placeholder: "请选择",
      options: [
        { label: "是", value: "YES" },
        { label: "否", value: "NO" },
      ],
    },
  },
  {
    label: "办理区域",
    dataIndex: "taxRegistrationId",
    type: "Select",
    params: {
      placeholder: "请选择",
      options: [],
      withAllowClear: true,
      showSearch: true,
      filterOption: (inputValue, option) => {
        return option?.label?.includes(inputValue)
      },
    },
  },
  {
    label: "关键字搜索",
    dataIndex: "query",
    type: "Input",
    params: {
      placeholder: "请输入开票方主体、纳税人识别号...",
    },
  },
]
