import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import SKTable from "@src/components/SkTable"
import { useAccess } from "@src/contents/useAccess"
import { Button, Modal, Popconfirm, Space, Switch, message } from "antd"
import React, { useRef, useState } from "react"
import { Link } from "react-router-dom"
import CodeModal from "./codeModal"
import columnsP from "./columns"
import "./index.scss"
import schema from "./schema"
import { delMpQrCode, getMpQrCodeList, getUploadByPath, putCode } from "./service"
/**
 * 二维码
 * @returns
 */
const TwoCode = () => {
  const tableRef = useRef<any>(null)
  const access = useAccess()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [useParams, setUseParams] = useState<any>({})
  const [numList, setNumList] = useState<number | string>(0)
  const [switchStuas, setSwitchStuas] = useState<boolean>(false)
  const [isCodeModal, setIsCodeModal] = useState<boolean>(false)
  const [titleType, setTitleType] = useState<"add" | "edit">("add")
  const [itemInfo, setItemInfo] = useState<any>({})
  const [imgSrc, setImgSrc] = useState<string>("")

  const handleRequestData = async (params: any) => {
    const { status, keywords, current, pageSize, createTimeEnd, createTimeStart, ...rest } = params
    setUseParams(params)
    const res: any = await getMpQrCodeList({
      keywords: keywords?.trim(),
      current,
      status,
      size: pageSize,
      ...rest,
      createTimeStart: createTimeStart && createTimeStart.format("YYYY-MM-DD") + " 00:00:00",
      createTimeEnd: createTimeEnd && createTimeEnd.format("YYYY-MM-DD") + " 23:59:59",
    })
    setNumList(res?.total)

    return {
      data: res?.records,
      total: res?.total,
    }
  }
  const upDataTable = () => {
    tableRef.current.upData()
  }
  // 删除
  const delHandle = async (id) => {
    const res: any = await delMpQrCode(id)
    if (res.code === 200) {
      message.success("删除成功")
      upDataTable()
    }
  }
  const columns: any = [
    ...columnsP,

    {
      title: "状态",
      dataIndex: "enable",
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={text}
              checkedChildren="启用"
              unCheckedChildren="禁用"
              onChange={async () => {
                setSwitchStuas(text)
                await putCode({ id: record.id, enable: !text })
                await upDataTable()
              }}
            />
          </>
        )
      },
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 200,
      fixed: "right",
      render: (text: any, record: any) => {
        return (
          <Space>
            <Link
              onClick={() => {
                setIsCodeModal(true)
                setItemInfo(record)
                getUploadByPath({ filePaths: [record.qrcode] }).then((e) => {
                  setImgSrc(e.data.files[0].downloadUrl)
                  console.log(e, "eeeeeeee")
                })
              }}
            >
              微信二维码
            </Link>
            <Link
              onClick={() => {
                setIsOpen(true)
                setTitleType("edit")
                setItemInfo(record)
              }}
            >
              编辑
            </Link>
            <Popconfirm title="确认删除？" onConfirm={async () => await delHandle(record?.id)}>
              <Link>删除</Link>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>二维码</span>
          </Space>
        }
        breadCrumb={[
          { label: "平台设置", path: "" },
          { label: "二维码", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <SKTable
          title={`二维码/${numList}`}
          ref={tableRef}
          toolbarRender={
            <>
              <Button
                type="primary"
                className="btn-add"
                onClick={() => {
                  setTitleType("add")
                  setIsOpen(true)
                }}
              >
                新增二维码
              </Button>
            </>
          }
          request={handleRequestData}
          columns={columns}
          searchFlag={true}
          search={{
            mode: "simple",
            schema: schema,
            column: 3,
          }}
        ></SKTable>
        <CodeModal
          isOpen={isOpen}
          OpenModalFn={(value: boolean) => {
            setIsOpen(value)
            setItemInfo({})
          }}
          cb={upDataTable}
          id="0"
          titleType={titleType}
          itemInfo={itemInfo}
        />
        <Modal
          onCancel={() => setIsCodeModal(false)}
          footer={null}
          wrapClassName="TwoCodeModal"
          open={isCodeModal}
          centered
          title="微信扫码"
          style={{ textAlign: "center" }}
          width={400}
        >
          <Space className="main" direction="vertical" align="center">
            <span className="title">{itemInfo.name}</span>
            <img src={imgSrc} alt="" width={250} height={250} />
          </Space>
          <div
            className="form_footer"
            style={{
              textAlign: "right",
              padding: 10,
              borderTop: "1px solid #f0f0f0",
            }}
          >
            <Button
              style={{ padding: "12px" }}
              onClick={(e) => {
                setIsCodeModal(false)
              }}
            >
              关闭
            </Button>
          </div>
        </Modal>
      </DetailPageContent>
    </DetailPageContainer>
  )
}
export default TwoCode
