import { useRequest } from "ahooks"
import { Button, Form, Input, Modal, Select, message } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { getTissueList } from "../../../service"
import { Production_PublicAccountEnum, Test_PublicAccountEnum } from "../constant"
import { postCode, putCode } from "../service"
type type_modal_operator = {
  (a: boolean): void
}
const { Option } = Select
const { TextArea } = Input

type type_user_modal = {
  isOpen: boolean
  OpenModalFn: type_modal_operator
  cb: () => void
  id: string
  titleType: "add" | "edit"
  itemInfo: any
}
const CodeModal: React.FC<type_user_modal> = ({
  isOpen,
  OpenModalFn,
  cb,
  id,
  titleType,
  itemInfo,
}) => {
  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()
  const [clientCode, setClientCode] = useState<string[]>([])
  const handleCancel = () => {
    form.resetFields()
    OpenModalFn(false)
  }
  const tissueListService = useRequest(getTissueList, {
    manual: true,
  })
  const postCodeService = useRequest(postCode, {
    debounceWait: 800,
    manual: true,
    onSuccess: (data) => {
      if (data.successful) {
        messageApi.open({
          type: "success",
          content: "新增成功",
          onClose: () => {
            handleCancel()
            cb()
          },
        })
      }
    },
  })
  const putCodeService = useRequest(putCode, {
    debounceWait: 800,
    manual: true,
    onSuccess: (data) => {
      if (data.successful) {
        messageApi.open({
          type: "success",
          content: "编辑成功",
          onClose: () => {
            handleCancel()
            cb()
          },
        })
      }
    },
  })
  useEffect(() => {
    tissueListService.run({
      size: -1,
      searchCount: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useMemo(() => {
    console.log(itemInfo, "itemInfo22")
    itemInfo.appId && form.setFieldsValue(itemInfo)
    const invitationCodes = itemInfo.relatedOrgIds
      ?.map((relatedOrgId) => {
        // 找到匹配的对象
        const matchedOrg = tissueListService.data?.data?.records?.find(
          (record) => record.id === relatedOrgId
        )
        // 如果找到匹配对象，返回其 invitationCode，否则返回 undefined
        return matchedOrg ? matchedOrg.invitationCode : undefined
      })
      ?.filter((code) => code !== undefined) // 过滤掉 undefined 值
    setClientCode(invitationCodes)
  }, [form, itemInfo, tissueListService.data?.data?.records])
  const onFinish = (values: any) => {
    const { appId, ...params } = values
    if (titleType === "add") {
      postCodeService.run({ type: "REPLY_TEXT", ...params })
    } else {
      putCodeService.run(params)
    }
  }

  return (
    <>
      <Modal
        open={isOpen}
        title={`${titleType == "add" ? "新增" : "编辑"}二维码`}
        onCancel={handleCancel}
        centered
        width={600}
        footer={null}
      >
        {contextHolder}
        <Form
          form={form}
          name="basic"
          labelWrap
          colon={false}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          // initialValues={value}
          onFinish={onFinish}
        >
          <Form.Item name="id" hidden={true} />
          <Form.Item label="二维码名称" name="name" rules={[{ required: true }]}>
            <Input placeholder="请输入" maxLength={20} showCount />
          </Form.Item>

          <Form.Item name="appId" label="公众号" rules={[{ required: true }]}>
            <Select
              options={
                process.env.REACT_APP_ENV == "production"
                  ? Production_PublicAccountEnum
                  : Test_PublicAccountEnum
              }
            />
          </Form.Item>

          <Form.Item
            name="relatedOrgIds"
            label="关联组织"
            extra={<span>客户码：{clientCode?.join("、")}</span>}
          >
            <Select
              mode="multiple"
              allowClear
              optionFilterProp="children"
              showSearch
              maxTagCount={6}
              onChange={async (e, option) => {
                let arr = []
                option.forEach((item) => {
                  arr.push(item.key)
                })
                setClientCode(arr)
              }}
              onSearch={async (e) => {
                await tissueListService.run({
                  keywords: e,
                  size: -1,
                  searchCount: true,
                })
              }}
            >
              {tissueListService.data?.data?.records?.map((buyer) => (
                <Option value={buyer.id} key={buyer.invitationCode}>
                  {buyer.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="retMsgTemp"
            rules={[{ required: true, message: "请输入公众号扫码回复文案" }]}
            extra="微信内打开公众号网页，点击右上角···，点击复制链接"
            label={<>公众号扫码回复文案</>}
          >
            <TextArea rows={4}></TextArea>
          </Form.Item>
          <Form.Item name="remark" label="备注">
            <TextArea></TextArea>
          </Form.Item>

          <Form.Item wrapperCol={{ span: 16 }} style={{ borderTop: "1px solid #f0f0f0" }}>
            <div
              className="form_footer"
              style={{ display: "flex", marginBottom: 10, paddingTop: 10 }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  textAlign: "right",
                  marginLeft: "110%",
                }}
              >
                <Button
                  style={{ marginRight: "12px" }}
                  onClick={(e) => {
                    handleCancel()
                  }}
                >
                  取消
                </Button>
                <Button
                  loading={postCodeService?.loading || putCodeService?.loading}
                  type="primary"
                  htmlType="submit"
                >
                  确定
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
export default CodeModal
