import React from "react"
import { PublicAccountEnum } from "./constant"

export default [
  {
    title: "二维码名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "关联组织",
    dataIndex: "relatedOrgName",
    key: "relatedOrgName",
    with: 220,
  },
  {
    title: "公众号",
    dataIndex: "appId",
    key: "appId",
    render: (text, row) => {
      return <>{PublicAccountEnum.filter((item) => item.value == text)[0].label}</>
    },
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    key: "createTime",
  },
]
