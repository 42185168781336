/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react"
import { Button, Row, Col, Card, Space, Modal, Divider, List, Form, message } from "antd"
import BreadCrumbBar from "@src/components/BreadCrumbBar"
import TableCom from "@src/components/TableCom"
import SearchForm from "@src/components/FormItem"
import { useParams, useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import styles from "./index.module.scss"
import { getTradingSchemeDetails, getTissueList, getCompanyList } from "../../promises"
import {
  getChangeType,
  channelOptions,
  orderTypesOptions,
  EntityLevelOptions,
  applyToOptions,
  intervalTypeOptions,
  priceTypeOptions,
  settingOptions,
  handSettingOptions,
  invoiceLimitsOptions,
  contractCreateTypeOptions,
  buyerSignTypeOptions,
  orderDrawerOptions,
  EntityTypes,
  PaymentEnum,
} from "../constant"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import Access from "@src/components/Access"
import { useAccess } from "@src/contents/useAccess"
const TradingSchemeDetail = () => {
  const navigate = useNavigate()
  const access = useAccess()
  const searchParams = useParams()
  const [info, setInfo] = useState<any>({})
  const [tissueList, setTissueList] = useState<any>([]) //组织列表
  const [isModalOpen, setIsModalOpen] = useState<any>({
    open: false,
    data: [],
  })
  const [resultData, setResultData] = useState<any>([])
  const [companyList, setCompanyList] = useState<any>([]) //缴税公司列表
  const [infoCompany, setInfoCompany] = useState<any>([]) //缴税公司列表
  const [infoResultData, setInfoResultData] = useState<any>([])

  const getInfo = async (id: any) => {
    if (!id) return
    const res = await getTradingSchemeDetails(id)
    if (res?.code === 200) {
      const result = res?.data?.settlementConfig.map((ie) => {
        if (ie?.enable) {
          return ie
        }
      })
      const settlementList = result?.filter(Boolean)
      const settlementRes = settlementList[0]?.settlementPaths?.filter((ie) => ie.taxCompanyId)
      setInfoResultData(settlementRes)
      setResultData(settlementList[0]?.settlementPaths)
      setInfo(res?.data)
    }
  }
  //组织列表
  const tissueInit = async () => {
    const response = await getTissueList({ size: -1, searchCount: true })
    if (response?.code == 200) {
      const result = response?.data?.records.map((ie) => {
        return {
          label: ie?.name,
          value: ie?.id,
        }
      })
      setTissueList(result)
    }
  }
  //查看缴税公司
  const handGetCompanyList = async () => {
    const response: any = await getCompanyList({})
    if (response.code == 200) {
      const res = response.data.map((ie) => {
        return {
          label: ie.receivingAccountName,
          value: ie.id,
        }
      })
      setCompanyList(res)
      const resultId = response.data.filter((ie) => ie?.id == infoResultData[0]?.taxCompanyId)
      setInfoCompany(resultId)
    }
  }
  useEffect(() => {
    if (infoResultData.length > 0) {
      handGetCompanyList()
    }
  }, [infoResultData])
  useEffect(() => {
    tissueInit()
  }, [])
  useEffect(() => {
    // handDetailsType();
    getInfo(searchParams?.id)
  }, [searchParams])
  const purchaseColumns = [
    {
      title: "作用对象",
      dataIndex: "applyTo",
      render: (t: string, item: any) => {
        return <span>{getChangeType(t, applyToOptions)}</span>
      },
    },
    {
      title: "区间类型",
      dataIndex: "intervalType",
      render: (t: string, item: any) => {
        return <span>{getChangeType(t, intervalTypeOptions)}</span>
      },
    },
    {
      title: "连续区间数量",
      dataIndex: "intervalCount",
    },
    {
      title: "含不含税价格",
      dataIndex: "priceType",
      render: (t: string, item: any) => {
        return <span>{getChangeType(t, priceTypeOptions)}</span>
      },
    },
    {
      title: "限额",
      dataIndex: "limitAmount",
    },
    {
      title: "单位区间最小额度(元)",
      dataIndex: "minAmount",
    },
  ]
  const accountColumns = [
    {
      title: "序号",
      dataIndex: "sort",
    },
    {
      title: "支付方",
      dataIndex: "payerRole",
      render: (t: string, item: any) => {
        return <span>{getChangeType(t, settingOptions)}</span>
      },
    },
    {
      title: "收款方",
      dataIndex: "payeeRole",
      render: (t: string, item: any) => {
        if (item?.payeeRole == "TAX_COMPANY") {
          const resultData = tissueList?.filter((ie) => ie.value == item.taxCompanyId)
          const resultDataTax = companyList?.filter((ie) => ie.value == item.taxCompanyId)
          return <span>{resultData[0]?.label || resultDataTax[0]?.label}</span>
        } else {
          return <span>{getChangeType(t, settingOptions)}</span>
        }
      },
    },
    {
      title: "支付项",
      dataIndex: "payOption",
      render: (t: string, item: any) => {
        return <span>{getChangeType(t, handSettingOptions)[0]}</span>
      },
    },
  ]
  const invoiceColumns = [
    // {
    //   title: "税区",
    //   dataIndex: "taxRegionId",
    //   render: (t: string, item: any) => {
    //     return <span>{getChangeType(t, invoiceLimitsOptions)}</span>
    //   },
    // },
    {
      title: "单张最高开票限额(V3)",
      dataIndex: "threeMax",
    },
    {
      title: "单张最高开票限额(V4)",
      dataIndex: "fourMax",
    },
  ]
  const contractColumns = [
    {
      title: "订单类型",
      dataIndex: "orderType",
      render: (t: string, item: any) => {
        return <span>{getChangeType(t, orderTypesOptions)}</span>
      },
    },
    {
      title: "渠道来源",
      dataIndex: "channel",
      render: (t: string, item: any) => {
        return <span>{getChangeType(t, channelOptions)}</span>
      },
    },
    {
      title: "合同生成方式",
      dataIndex: "contractCreateType",
      render: (t: string, item: any) => {
        return <span>{getChangeType(t, contractCreateTypeOptions)}</span>
      },
    },
    {
      title: "合同模版名称",
      dataIndex: "contractName",
    },
    {
      title: "选择购买方",
      dataIndex: "buyerId",
    },
    {
      title: "选择销售方",
      dataIndex: "sellerId",
    },
    {
      title: "购买方签署方式",
      dataIndex: "buyerSignType",
      render: (t: string, item: any) => {
        return <span>{getChangeType(t, buyerSignTypeOptions)}</span>
      },
    },
    {
      title: "销售方签署方式",
      dataIndex: "sellerSignType",
      render: (t: string, item: any) => {
        return <span>{getChangeType(t, buyerSignTypeOptions)}</span>
      },
    },
    {
      title: "优先级",
      dataIndex: "sort",
    },
  ]
  const handTissUeList = (data) => {
    if (data.length > 0) {
      const result = data.map((ie) => {
        return ie?.label
      })
      return result.filter(Boolean)
    }
  }
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <Button onClick={() => navigate("/entity/transaction/tradingScheme")}>返回</Button>
            <span style={{ paddingLeft: 4 }}>交易方案详情</span>
          </Space>
        }
        breadCrumb={[
          { label: "交易中心", path: "" },
          { label: "交易方案", path: "" },
          { label: "详情", path: "" },
        ]}
        rightContent={
          <Access accessible={access.op_entity_tradingScheme_feture_edit}>
            <Button
              type="primary"
              style={{ background: "#1890FF" }}
              onClick={() =>
                navigate(`/entity/transaction/TradingSchemeDetail/${searchParams?.id}`)
              }
            >
              编辑
            </Button>
          </Access>
        }
      ></DetailPageHeader>
      <DetailPageContent>
        <div className={styles.supply_order_container}>
          <Card title="基础信息">
            <Row>
              <Col span="8">
                <div className={styles.info_items}>
                  <span className={styles.label}>交易方案名称:</span>
                  <span className={styles.value}>{info?.name ? info?.name : ""}</span>
                </div>
              </Col>
              <Col span="8">
                <div className={styles.info_items} style={{ width: 500 }}>
                  <span className={styles.label}>交易方案有效期:</span>
                  <span className={styles.value}>
                    {info?.effectiveTime} 至 {info?.expirationTime}
                  </span>
                </div>
              </Col>
            </Row>
          </Card>
          <Card title="交易类型" style={{ marginTop: 15 }}>
            <Row>
              {/* <Col span="8">
                  <div className={styles.info_items}>
                    <span className={styles.label}>渠道限定:</span>
                    {getChangeType(info?.channel, channelOptions)}
                  </div>
                </Col> */}
              <Col span="8">
                <div className={styles.info_items}>
                  <span className={styles.label}>交易类型:</span>
                  {/* {info?.tradeType?.scope ? info?.tradeType?.scope : ""} */}
                  <span className={styles.value}>
                    {getChangeType(info?.tradeType?.scope, orderTypesOptions)}
                  </span>
                </div>
              </Col>
            </Row>
            {/* <div className={`${styles.details_type}`}>采购订单限制</div>
              <Row style={{ paddingTop: 24 }}>
                <Col span="8">
                  <div className={styles.info_items}>
                    <span className={styles.label}>是否需要供货协议:</span>
                    {info?.tradeType?.purchase?.needSupplyAgreement
                      ? "需要供货协议"
                      : "不需要供货协议"}
                  </div>
                </Col>
              </Row> */}

            {/* <div className={`${styles.details_type} `}>补差订单限制</div>
              <Row style={{ paddingTop: 24 }}>
                <Col span="8">
                  <div className={styles.info_items}>
                    <span className={styles.label}>关联订单货款支付要求:</span>
                    {info?.tradeType?.difference?.relatedOrderPaid
                      ? "支付完成"
                      : "无须检验是否支付完成"}
                  </div>
                </Col>
                <Col span="8">
                  <div className={styles.info_items}>
                    <span className={styles.label}>关联订单开票要求:</span>
                    {info?.tradeType?.difference?.relatedOrderInvoiced
                      ? "开票完成"
                      : "无须检验是否开票完成"}
                  </div>
                </Col>
                <Col span="8">
                  <div className={styles.info_items}>
                    <span className={styles.label}>补差比例限制:</span>
                    {info?.tradeType?.difference?.maxDifferenceRatio}
                  </div>
                </Col>
              </Row> */}
          </Card>
          <Card title="购销方限定" style={{ marginTop: 15 }}>
            <div style={{ fontSize: 16, fontWeight: 600 }}>销方限定</div>
            <Row style={{ paddingTop: 16 }}>
              <Col span="8">
                <div className={styles.info_items}>
                  <span className={styles.label}>类型限定:</span>
                  <span className={styles.value}>
                    {getChangeType(info?.sellerConfig?.types, EntityTypes)}
                  </span>
                </div>
              </Col>
              <Col span="8">
                <div className={styles.info_items} style={{ minWidth: 600 }}>
                  <span className={styles.label}>设置黑名单：</span>
                  <span className={styles.value}>
                    {getChangeType(info?.sellerConfig?.blackList, tissueList)[0]}
                  </span>
                  <a
                    href="#"
                    style={{ paddingLeft: 16 }}
                    onClick={() =>
                      setIsModalOpen({
                        open: true,
                        data: getChangeType(info?.sellerConfig?.blackList, tissueList),
                      })
                    }
                  >
                    查看全部列表
                  </a>
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: 10 }}>
              {/* <Col span="8">
                  <div className={styles.info_items}>
                    <span className={styles.label}>主体级别：</span>
                    {getChangeType(info?.sellerConfig?.entityLevel, EntityLevelOptions)}
                    {info?.sellerConfig?.entityLevel}
                  </div>
                </Col>
                <Col span="8">
                  <div className={styles.info_items}>
                    <span className={styles.label}>税区限定：</span>
                    {info?.sellerConfig?.taxRegion}
                  </div>
                </Col> */}
              <Col span="8">
                <div className={styles.info_items} style={{ minWidth: 600 }}>
                  <span className={styles.label}>设置白名单：</span>
                  <span className={styles.value}>
                    {info?.sellerConfig?.whiteList == "ALL"
                      ? "全部"
                      : getChangeType(info?.sellerConfig?.whiteList, tissueList)[0]}
                  </span>
                  <a
                    href="#"
                    style={{ paddingLeft: 16 }}
                    onClick={() =>
                      setIsModalOpen({
                        open: true,
                        // data: getChangeType(info?.sellerConfig?.whiteList, tissueList),
                        data:
                          info?.sellerConfig?.whiteList == "ALL"
                            ? handTissUeList(tissueList)
                            : getChangeType(info?.sellerConfig?.whiteList, tissueList),
                      })
                    }
                  >
                    查看全部列表
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span="8">
                <div className={styles.info_items} style={{ marginTop: 10 }}>
                  <span className={styles.label}>额度限定：</span>
                  <a href="#" onClick={() => message.warning("暂未开放")}>
                    查看说明
                  </a>
                </div>
              </Col>
            </Row>
            <div className={styles.mar_top_16}>
              <TableCom
                columns={purchaseColumns}
                dataSource={info?.sellerConfig?.limitAmountConfig}
                total={0}
                pagination={false}
                scroll={{
                  x: true,
                }}
              ></TableCom>
            </div>
            <div
              className={`${styles.details_type}`}
              style={{ fontSize: 16, fontWeight: 600, paddingTop: 24 }}
            >
              购方限定
            </div>
            <Row style={{ paddingTop: 16 }}>
              <Col span="8">
                <div className={styles.info_items}>
                  <span className={styles.label}>类型限定:</span>
                  <span className={styles.value}>
                    {getChangeType(info?.buyerConfig?.types, EntityTypes)}
                  </span>
                </div>
              </Col>
              <Col span="8">
                <div className={styles.info_items} style={{ minWidth: 600 }}>
                  <span className={styles.label}>设置黑名单：</span>
                  <span className={styles.value}>
                    {getChangeType(info?.buyerConfig?.blackList, tissueList)[0]}
                  </span>
                  <a
                    href="#"
                    style={{ paddingLeft: 16 }}
                    onClick={() =>
                      setIsModalOpen({
                        open: true,
                        data: getChangeType(info?.buyerConfig?.blackList, tissueList),
                      })
                    }
                  >
                    查看全部列表
                  </a>
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: 16 }}>
              <Col span="8">
                <div className={styles.info_items}>
                  <span className={styles.label}>是否查询可用授信额度:</span>
                  <span className={styles.value}>
                    {info?.sellerConfig?.requiredInvoicingCreditFlag ? "需要查询" : "不需要查询"}
                  </span>
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: 16 }}>
              <Col span="8">
                <div className={styles.info_items} style={{ minWidth: 600 }}>
                  <span className={styles.label}>设置白名单:</span>

                  <span className={styles.value}>
                    {info?.buyerConfig?.whiteList == "ALL"
                      ? "全部"
                      : getChangeType(info?.buyerConfig?.whiteList, tissueList)[0]}
                  </span>

                  <a
                    href="#"
                    style={{ paddingLeft: 16 }}
                    onClick={() =>
                      setIsModalOpen({
                        open: true,
                        data:
                          info?.buyerConfig?.whiteList == "ALL"
                            ? handTissUeList(tissueList)
                            : getChangeType(info?.buyerConfig?.whiteList, tissueList),
                      })
                    }
                  >
                    查看全部列表
                  </a>
                </div>
              </Col>
            </Row>
          </Card>
          <Card title="费率设定" style={{ marginTop: 15 }}>
            <Row>
              <Col span="6">
                <div className={styles.info_items}>
                  <span className={styles.label}>增值税率:</span>
                  <span className={styles.value}>
                    {parseFloat((info?.rateConfig?.valueAddTaxRate * 100).toFixed(10)) + "%"}
                  </span>
                </div>
              </Col>
              <Col span="6">
                <div className={styles.info_items}>
                  <span className={styles.label}>代扣增值税率:</span>
                  <span className={styles.value}>
                    {parseFloat((info?.rateConfig?.valueAddTaxCollectionRate * 100).toFixed(10)) +
                      "%"}
                  </span>
                </div>
              </Col>
              <Col span="6">
                <div className={styles.info_items}>
                  <span className={styles.label}>平台预扣费率:</span>
                  <span className={styles.value}>
                    {parseFloat((info?.rateConfig?.withholdTaxRate * 100).toFixed(10)) + "%"}
                  </span>
                </div>
              </Col>
              <Col span="6">
                <div className={styles.info_items}>
                  <span className={styles.label}>（反向开票）预扣个税及附加费率:</span>
                  <span className={styles.value}>
                    {parseFloat((info?.rateConfig?.incomeTaxRateReverse * 100).toFixed(10)) + "%"}
                  </span>
                </div>
              </Col>
            </Row>
          </Card>
          <Card title="结算设定" style={{ marginTop: 15 }}>
            <TableCom
              columns={accountColumns}
              dataSource={resultData}
              total={0}
              pagination={false}
              scroll={{
                x: true,
              }}
            ></TableCom>
          </Card>
          <Card title="预约支付" style={{ marginTop: 15 }}>
            <Col span="12" style={{ display: "flex" }}>
              <div className={styles.info_items}>
                <span className={styles.label}>销售方预约支付渠道:</span>
                <span className={styles.value}>
                  {PaymentEnum[info?.paymentPlanConfig?.channel]}
                </span>
              </div>
              <div className={styles.info_items} style={{ marginLeft: 24 }}>
                <span className={styles.label}>允许销售方取消预约支付:</span>
                <span className={styles.value}>
                  {info?.paymentPlanConfig?.allowedCancelPaymentPlan ? "是" : "否"}
                </span>
              </div>
            </Col>
          </Card>
          <Card title="合同设定" style={{ marginTop: 15 }}>
            <Row>
              <Col span="8">
                <div className={styles.info_items}>
                  <span className={styles.label}>采购合同:</span>
                  <span className={styles.value}>
                    {info?.contractConfig?.needPurchaseContract ? "需要校验" : "不需要校验"}
                  </span>
                </div>
              </Col>
              <Col span="8">
                <div className={styles.info_items} style={{ minWidth: 600 }}>
                  <span className={styles.label}>跳过校验购方名单：</span>
                  {info?.contractConfig?.purchaseContractWhiteList?.length == 0 ? (
                    "-"
                  ) : (
                    <a
                      href="#"
                      style={{ paddingLeft: 16 }}
                      onClick={() =>
                        setIsModalOpen({
                          open: true,
                          data: getChangeType(
                            info?.contractConfig?.purchaseContractWhiteList,
                            tissueList
                          ),
                        })
                      }
                    >
                      查看全部列表
                    </a>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col span="8">
                <div className={styles.info_items}>
                  <span className={styles.label}>供货协议：</span>
                  <span className={styles.value}>
                    {info?.contractConfig?.needSupplyContract ? "需要校验" : "不需要校验"}
                  </span>
                </div>
              </Col>
              <Col span="8">
                <div className={styles.info_items} style={{ minWidth: 600 }}>
                  <span className={styles.label}>跳过校验购方名单：</span>
                  {info?.contractConfig?.supplyContractWhiteList?.length == 0 ? (
                    "-"
                  ) : (
                    <a
                      href="#"
                      style={{ paddingLeft: 16 }}
                      onClick={() =>
                        setIsModalOpen({
                          open: true,
                          data: getChangeType(
                            info?.contractConfig?.supplyContractWhiteList,
                            tissueList
                          ),
                        })
                      }
                    >
                      查看全部列表
                    </a>
                  )}
                </div>
              </Col>
            </Row>
          </Card>
          <Card title="申请开票设定" style={{ marginTop: 15 }}>
            <Row>
              <Col span="8">
                <div className={styles.info_items}>
                  <span className={styles.label}>申请开票方:</span>
                  {info?.applyInvoiceConfig?.drawer == "ALL" ? (
                    "全部"
                  ) : (
                    <span className={styles.value}>
                      {getChangeType(info?.applyInvoiceConfig?.drawer, orderDrawerOptions)}
                    </span>
                  )}
                </div>
              </Col>
              <Col span="8">
                <div className={styles.info_items}>
                  <span className={styles.label}>允许的开票类型:</span>
                  <span className={styles.value}>
                    {info?.applyInvoiceConfig?.type == "SPECIAL" ? "专票" : "普票"}
                  </span>
                </div>
              </Col>
              <Col span="8">
                <div className={styles.info_items}>
                  <span className={styles.label}>验证税费和附加税费:</span>
                  <span className={styles.value}>
                    {info?.applyInvoiceConfig?.checkPayment ? "需要验证" : "不需要验证"}
                  </span>
                </div>
              </Col>
              <Col span="8">
                <div className={styles.info_items} style={{ paddingTop: 5 }}>
                  <span className={styles.label}>货款支付验证:</span>
                  <span className={styles.value}>
                    {info?.applyInvoiceConfig?.checkTaxPaid ? "需要验证" : "不需要验证"}
                  </span>
                </div>
              </Col>
              <Col span="8">
                <div className={styles.info_items}>
                  <span className={styles.label}>验证物流是否完成:</span>
                  <span className={styles.value}>
                    {info?.applyInvoiceConfig?.checkLogistic ? "需要验证" : "不需要验证"}
                  </span>
                </div>
              </Col>
              <Col span="8">
                <div className={styles.info_items} style={{ paddingTop: 5 }}>
                  <span className={styles.label}>验证购买方合同签署状态是否完成:</span>
                  <span className={styles.value}>
                    {info?.applyInvoiceConfig?.checkBuyerSign ? "需要验证" : "不需要验证"}
                  </span>
                </div>
              </Col>
              <Col span="8">
                <div className={styles.info_items} style={{ paddingTop: 5 }}>
                  <span className={styles.label}>验证销售方合同签署状态是否完成:</span>
                  <span className={styles.value}>
                    {info?.applyInvoiceConfig?.checkSellerSign ? "需要验证" : "不需要验证"}
                  </span>
                </div>
              </Col>
              <Col span="8">
                <div className={styles.info_items} style={{ paddingTop: 5 }}>
                  <span className={styles.label}>收款人:</span>
                  <span className={styles.value}>{info?.applyInvoiceConfig?.payeeName}</span>
                </div>
              </Col>
              <Col span="8">
                <div className={styles.info_items} style={{ paddingTop: 5 }}>
                  <span className={styles.label}>复核人:</span>
                  <span className={styles.value}>{info?.applyInvoiceConfig?.reviewerName}</span>
                </div>
              </Col>
              <Col span="8">
                <div className={styles.info_items} style={{ paddingTop: 5 }}>
                  <span className={styles.label}>购销方开户行及账号:</span>
                  <span className={styles.value}>
                    {info?.applyInvoiceConfig?.displayBankAccount
                      ? "显示购销方开户银行和银行帐号"
                      : "不显示购销方开户银行和银行帐号"}
                  </span>
                </div>
              </Col>
              <Col span="8">
                <div className={styles.info_items} style={{ paddingTop: 5 }}>
                  <span className={styles.label}>备注:</span>
                  <span className={styles.value}>{info?.applyInvoiceConfig?.remark}</span>
                </div>
              </Col>
            </Row>
            <div className={`${styles.details_type}`} style={{ fontSize: 16, fontWeight: 600 }}>
              单张发票最高限额
            </div>
            <div className={styles.mar_top_16}>
              <TableCom
                columns={invoiceColumns}
                dataSource={[info?.applyInvoiceConfig?.invoiceLimit]}
                total={0}
                pagination={false}
                scroll={{
                  x: true,
                }}
              ></TableCom>
            </div>
            <Row style={{ paddingTop: 24 }}>
              <Col span="8">
                <div className={styles.info_items}>
                  <span className={styles.label}>开票审核要求:</span>
                  <span className={styles.value}>{info?.applyInvoiceConfig?.approvalInvoice}</span>
                </div>
              </Col>
            </Row>
          </Card>

          <div className={styles.supply_order_info}>
            {/* <div className={`${styles.title} ${styles.mar_top_16}`}>
              合同签署设定{" "}
              <span className={`${styles.detail_span} `}>
                每一行代表一种合同的签署方式，当满足多条时，按优先级进行选择，1优先级最高，数字越大优先级越低
              </span>
            </div>
            <div className={styles.info_values}>
              <div className={styles.mar_top_16}>
                <TableCom
                  columns={contractColumns}
                  dataSource={info?.strategyContractEntityList}
                  total={0}
                  pagination={false}
                  scroll={{
                    x: true,
                  }}
                ></TableCom>
              </div>
            </div> */}
          </div>
        </div>
        <Modal title="白名单列表" open={isModalOpen.open} footer={null}>
          <div>
            <List
              style={{ border: "none" }}
              className={styles.modalList}
              bordered
              dataSource={isModalOpen.data}
              renderItem={(item: any) => <List.Item>{item}</List.Item>}
            />
          </div>
          <Form.Item wrapperCol={{ span: 16 }} style={{ borderTop: "1px solid #f0f0f0" }}>
            <div
              className="form_footer"
              style={{ display: "flex", marginBottom: 10, paddingTop: 10 }}
            >
              <div style={{ display: "flex", flex: 1, textAlign: "right", marginLeft: "105%" }}>
                <Button
                  style={{ marginRight: "12px" }}
                  onClick={() => setIsModalOpen({ open: false, data: [] })}
                >
                  取消
                </Button>
                <Button type="primary" onClick={() => setIsModalOpen({ open: false, data: [] })}>
                  确定
                </Button>
              </div>
            </div>
          </Form.Item>
        </Modal>
      </DetailPageContent>
    </DetailPageContainer>
  )
}

export default TradingSchemeDetail
