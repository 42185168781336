const withAllowClear = {
  allowClear: true,
}
export default {
  type: "object",
  labelWidth: 106,
  properties: {
    used: {
      title: "状态",
      type: "string",
      widget: "select",
      enum: [false, true],
      enumNames: ["未使用", "已使用"],
      placeholder: "请选择",
      props: withAllowClear,
    },
    code: {
      title: "关键字搜索",
      type: "string",
      widget: "input",
      placeholder: "请输入优惠券搜索",
      props: withAllowClear,
    },
  },
}
