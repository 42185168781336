import {
  OP_TRADE,
  PORT1,
  OP_LOGISTIC,
  OP_USER,
  OP_SOURCE,
  OP_RESOURCE,
} from "../api/config/servicePort"
import axios from "axios"
import UtilOp from "../services/serviceOp"
const utilOp = UtilOp.getInstance()

//查询应用管理列表
export const getOperAppsLists = (values?: any) => {
  return utilOp.httpGet(OP_USER + "/authorisations/components", values)
}

export const getPaymentInfoByImage = (url: string) => {
  return utilOp
    .httpGet<Omit<RequestData.Payment.OfflinePaymentUpload["receipts"][0], "fileId">>(
      OP_RESOURCE + `/ocr/receipt`,
      {
        url: url,
      }
    )
    .then((res) => res.data)
}

// * 获取组织列表
export const getOrgs = () => {
  return utilOp.httpGet(OP_USER + "/authorisations/organizations")
}
// * 切换组织
export const changeOrg = (params = {}) => {
  return utilOp.httpPost(PORT1 + "/auth/switchOrg", params)
}
// * 退出接口
export const logout = (params: { token: string }) => {
  return utilOp.httpDeletel(PORT1 + "/oauth/logout", params)
}
// 获取文件
export const getFileUrlById = (fileIds: string) => {
  return utilOp
    .httpGet(OP_SOURCE + `/files/ossUrlZoom`, { zoom: false, fileIds })
    .then((res) => res.data)
}
