// 公众号类型
export const Production_PublicAccountEnum = [
  {
    value: "wx01ee07398b1501ca",
    label: "新再生客户服务",
  },
]

export const Test_PublicAccountEnum = [
  {
    value: "wx27765a2f20945d71",
    label: "新之回收体系",
  },
]

export const PublicAccountEnum = [
  {
    value: "wx27765a2f20945d71",
    label: "新之回收体系",
  },
  {
    value: "wx01ee07398b1501ca",
    label: "新再生客户服务",
  },
]
