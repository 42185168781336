const withAllowClear = {
  allowClear: true,
}
export default {
  type: "object",
  labelWidth: 100,
  properties: {
    enable: {
      title: "状态",
      type: "string",
      widget: "select",
      enum: [true, false],
      enumNames: ["启用", "禁用"],
      placeholder: "请选择",
      props: withAllowClear,
    },
    appId: {
      title: "公众号",
      type: "string",
      widget: "select",
      enum: [
        process.env.REACT_APP_ENV == "production" ? "wx01ee07398b1501ca" : "wx27765a2f20945d71",
      ],
      enumNames: [process.env.REACT_APP_ENV == "production" ? "新再生客户服务" : "新之回收体系"],
      placeholder: "请选择",
      props: withAllowClear,
    },
    checkTimer: {
      bind: ["createTimeStart", "createTimeEnd"],
      title: "创建日期",
      type: "data",
      widget: "XZDateRange",
      props: withAllowClear,
    },
    relatedOrgName: {
      title: "关联组织",
      type: "string",
      widget: "input",
      placeholder: "请输入",
      props: withAllowClear,
    },
    keywords: {
      title: "二维码名称",
      type: "string",
      widget: "input",
      placeholder: "输入二维码名称搜索",
      props: withAllowClear,
    },
  },
}
