/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { message } from "antd"
import axios from "axios"
import qs from "qs"
let current_env = process.env.REACT_APP_ENV || "production"
console.log(process.env, current_env, "process")
export interface Result<T = any> {
  code: number
  message: string
  data: T
}
export const cross_env = {
  development: "https://dev.api.saikul.com",
  test: "https://test.api.saikul.com",
  production: "https://rscm-api.saikul.com",
  jianlong: "https://rscm-api.xyrscm.com",
  shop: "https://rscm-api.nbcoop.com.cn",
}[current_env]
// 获取上传地址

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error?.response?.data?.message) {
      message.error(error.response.data.message)
    }
    if (error?.response?.config?.responseType == "blob") {
      let blob = new Blob([error?.response?.data])
      let reader = new FileReader()
      reader.readAsText(blob, "utf-8")
      reader.onload = function () {
        try {
          let data = JSON.parse((reader as any).result)
          if (data?.msg) {
            message.error(data?.msg)
          }
        } catch (e) {
          message.error("系统错误")
        }
      }
    } else if (error?.response?.data instanceof ArrayBuffer) {
      const td = new TextDecoder("utf-8")
      const dataStr = td.decode(error?.response?.data)
      const okData = JSON.parse(dataStr ?? "{}")
      if (okData?.msg) {
        message.error(okData.msg)
      }
    } else {
      if (error?.response?.data?.msg) {
        message.error(error?.response?.data?.msg)
      }
    }
  }
)

class Util {
  private sessionToken: string | null
  private static _instance: Util | null = null
  private outTime: number = 60 * 60 * 1000
  private rootUrl: string
  public lastTime = new Date().getTime()
  public Axios
  constructor() {
    this.rootUrl = cross_env || "https://test.api.saikul.com"
    this.sessionToken = localStorage?.getItem("operation_authorization")
    this.lastTime = new Date().getTime()
    this.Axios = axios.create({
      timeout: 60000,
      headers: {
        post: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      },
    })
  }
  static getInstance() {
    if (!Util._instance) {
      Util._instance = new Util()
    }
    return Util._instance
  }
  public clearLog() {
    localStorage.clear()
    sessionStorage.clear()
  }
  public setCustomHeaders(headers: Record<string, string>) {
    this.Axios.defaults.headers.common = {
      ...this.Axios.defaults.headers.common,
      ...headers,
    }
  }
  public setHeader(): Promise<string> {
    let field = JSON.parse(window.localStorage?.getItem("operation_authorization") as string)
    return new Promise((resolve) => {
      this.Axios = axios.create({
        timeout: 20000,
        headers: {
          post: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${field?.["access_token_op"]}`,
          },
          get: {
            Authorization: `Bearer ${field?.["access_token_op"]}`,
          },
          patch: {
            Authorization: `Bearer ${field?.["access_token_op"]}`,
          },
          put: {
            Authorization: `Bearer ${field?.["access_token_op"]}`,
          },
          delete: {
            Authorization: `Bearer ${field?.["access_token_op"]}`,
          },
        },
      })
      resolve("success")
    })
  }
  public setSessiontoken(token: string) {
    window.localStorage?.setItem("operation_authorization", token)
  }
  public isLogin(): boolean {
    if (this.sessionToken) {
      return true
    }
    return false
  }
  public triggerLoading(value: boolean) {
    let loadSelector: any = document?.querySelector("#global-loading") as HTMLElement
    if (value) {
      loadSelector!.style!.cssText = "display: flex"
    } else {
      loadSelector!.style!.cssText = "display: none"
    }
  }
  public overtimeLogOut() {
    const app = document.getElementById("root") as HTMLElement
    app.addEventListener("mousemove", () => {
      this.lastTime = new Date().getTime()
    })
    const trigger = () => {
      let currentTime = new Date().getTime()
      if (currentTime - this.lastTime > this.outTime) {
        localStorage.removeItem("operation+authorization_token")
        window.location.href = "/login"
      }
    }
    window.setInterval(trigger, 5000)
  }
  public httpGet<T = any>(
    url: string,
    params?: any,
    customHeaders?: any,
    setHeader: boolean = true,
    defaultUrl = this.rootUrl,
    isTooltip = true
  ): Promise<Result<T>> {
    if (setHeader) {
      this.setHeader()
    }
    let headers = {
      ...(this.Axios.defaults.headers.common || {}),
    }
    if (customHeaders) {
      this.setCustomHeaders(customHeaders)
    }
    return new Promise((resolve, reject) => {
      this.Axios({
        method: "get",
        url: defaultUrl + url,
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false })
        },
        headers,
      })
        .then((response: any) => {
          if (
            isTooltip &&
            response.data?.code &&
            response.data.code !== 200 &&
            response?.data?.message !== ""
          ) {
            message.error(response?.data?.message)
          }
          resolve(response.data)
        })
        .catch((error) => {
          if ([401, 4032].indexOf(error?.response?.data?.code) > -1) {
            this.clearLog()
            window.location.href = "/login"
          }
          if ([500].indexOf(error?.response?.data?.code) > -1) {
            resolve(error)
          }
          reject(error)
        })
    })
  }
  public httpPost(url: string, body?: any, config?: any, defaultUrl = this.rootUrl) {
    this.setHeader()
    return new Promise((resolve, reject) => {
      this.Axios.post(defaultUrl + url, body, config)
        .then((response: any) => {
          if ([401, 4032].indexOf(response.data.code) > -1) {
            this.clearLog()
            window.location.href = "/login"
          }
          if (response.data.code == 200) {
            resolve(response.data)
          } else {
            if (response.data.message) message.error(response.data.message)
            resolve(response)
          }
        })
        .catch((error) => {
          message.error(error?.response?.data?.message)
          if ([401, 4032].indexOf(error.response.data.code) > -1) {
            this.clearLog()
            window.location.href = "/login"
          }
          if ([500].indexOf(error?.response?.data?.code) > -1) {
            resolve(error)
          }
          reject(error)
        })
    })
  }
  public httpPut(url: string, body: any, config?: any, defaultUrl = this.rootUrl) {
    this.setHeader()
    return new Promise((resolve, reject) => {
      this.Axios.put(defaultUrl + url, body, config)
        .then((response: any) => {
          if ([401, 4032].indexOf(response.data.code) > -1) {
            this.clearLog()
            window.location.href = "/login"
          }
          if (response.data.code == 200) {
            resolve(response.data)
          } else {
            message.error(response.data.message)
            resolve(response)
          }
        })
        .catch((error) => {
          if ([401, 4032].indexOf(error.response.data.code) > -1) {
            this.clearLog()
            window.location.href = "/login"
          }
          reject(error)
        })
    })
  }
  public httpDeletel(url: string, param?: any, setHeader: boolean = true) {
    if (setHeader) {
      this.setHeader()
    }
    return new Promise((resolve, reject) => {
      this.Axios.delete(this.rootUrl + url, { data: param })
        .then((response) => {
          if (response.data.code == 200) {
            resolve(response.data)
          } else {
            message.error(response.data.message)
            resolve(response)
          }
        })
        .catch((error) => {
          console.log(error, "error delete")
          if ([401, 4032].indexOf(error.response.data.code) > -1) {
            this.clearLog()
            window.location.href = "/login"
          } else {
            message.error(error.data.message)
          }
          reject(error)
        })
    })
  }
  public httpUpload(url: string, body: any, config: any) {
    this.setHeader()
    return new Promise((resolve, reject) => {
      this.Axios.put(this.rootUrl + url, body, config)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          if ([401, 4032].indexOf(error?.response?.data?.code) > -1) {
            this.clearLog()
            window.location.href = "/login"
          }
          reject(error)
        })
    })
  }
  public httpGetDownload(url: string, params?: any, file?: any) {
    this.setHeader()
    return new Promise((resolve, reject) => {
      this.Axios({
        method: "POST",
        url: this.rootUrl + url,
        params: params,
        responseType: "blob",
      })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/zip" })
          let reader = new FileReader()
          reader.readAsText(blob, "utf-8")
          reader.onload = function () {
            try {
              let data = JSON.parse((reader as any).result)
              console.log(data, "data")

              if (data.code !== 200) {
                return message.error(data.message)
              }
              return
            } catch (e) {
              // message.error('系统错误')
              let url = window.URL.createObjectURL(blob)
              const link = document.createElement("a") // 创建a标签
              link.style.display = "none"
              link.href = url
              link.setAttribute("download", `${file}.zip`)
              document.body.appendChild(link)
              link.click()
              URL.revokeObjectURL(url) // 释放内存
            }
          }

          resolve(response)
        })
        .catch((error) => {
          console.log(error, "error")
        })
    })
  }
  public httpPostDownload(url: string, params?: any, file?: any) {
    this.setHeader()
    return new Promise((resolve, reject) => {
      this.Axios({
        method: "post",
        url: this.rootUrl + url,
        data: params,
        responseType: "blob",
      })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/zip" })
          let reader = new FileReader()
          reader.readAsText(blob, "utf-8")
          reader.onload = function () {
            try {
              let data = JSON.parse((reader as any).result)
              console.log(data, "data")

              if (data.code !== 200) {
                return message.error(data.message)
              }
              return
            } catch (e) {
              // message.error('系统错误')
              let url = window.URL.createObjectURL(blob)
              const link = document.createElement("a") // 创建a标签
              link.style.display = "none"
              link.href = url
              link.setAttribute("download", `${file}.pdf`)
              document.body.appendChild(link)
              link.click()
              URL.revokeObjectURL(url) // 释放内存
            }
          }

          resolve(response)
        })
        .catch((error) => {
          console.log(error, "error")
        })
    })
  }
  public httpPatch(url: string, body?: any, config?: any) {
    this.setHeader()
    return new Promise((resolve, reject) => {
      this.Axios.patch(this.rootUrl + url, body, config)
        .then((response: any) => {
          if ([401, 4032].indexOf(response.data.code) > -1) {
            this.clearLog()
            window.location.href = "/login"
          }
          if (response.data.code == 200) {
            resolve(response.data)
          } else {
            message.error(response.data.message)
          }
        })
        .catch((error) => {
          message.error(error?.response?.data?.message)
          if ([401, 4032].indexOf(error.response.data.code) > -1) {
            this.clearLog()
            window.location.href = "/login"
          }
          reject(error)
        })
    })
  }
}

export default Util
