import { DatePicker, Space, Tag } from "antd"
import React, { memo } from "react"
import { RangePickerProps } from "antd/es/date-picker"
import dayjs from "dayjs"
import quarterOfYear from "dayjs/plugin/quarterOfYear"
dayjs.extend(quarterOfYear)
export const rangePickerOptions: { label: string; value: [dayjs.Dayjs, dayjs.Dayjs] }[] = [
  { label: "今日", value: [dayjs().add(0, "days"), dayjs().add(0, "days")] },
  { label: "昨天", value: [dayjs().add(-1, "days"), dayjs().add(-1, "days")] },
  {
    label: "本周",
    value: [dayjs().startOf("week").add(1, "day"), dayjs().endOf("week").add(1, "day")],
  },
  { label: "本月", value: [dayjs().startOf("month"), dayjs().endOf("month")] },
  {
    label: "上月",
    value: [dayjs().add(-1, "months").startOf("month"), dayjs().add(-1, "months").endOf("month")],
  },
  { label: "本季度", value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")] },
  {
    label: "上季度",
    value: [
      dayjs().add(-1, "quarter").startOf("quarters"),
      dayjs().add(-1, "quarters").endOf("quarters"),
    ],
  },
  { label: "本年", value: [dayjs().startOf("years"), dayjs().endOf("years")] },
  {
    label: "上年",
    value: [dayjs().add(-1, "y").startOf("years"), dayjs().add(-1, "years").endOf("years")],
  },
]
type props = {} & RangePickerProps
export type CustomRangePickerProps = props
export const CustomRangePicker: React.FC<React.PropsWithChildren<CustomRangePickerProps>> = memo(
  (props) => {
    const { value, onChange, ...rest } = props
    return (
      <>
        <DatePicker.RangePicker
          onChange={onChange}
          value={value?.filter(Boolean)?.map(dayjs) as any}
          {...rest}
          renderExtraFooter={() => (
            <Space>
              {rangePickerOptions.map((item) => (
                <Tag
                  style={{ cursor: "pointer" }}
                  key={item.label}
                  color="blue"
                  onClick={() => {
                    onChange?.(
                      item.value,
                      item.value.map((date) => date.format("YYYY-MM-DD HH:mm:ss")) as [
                        string,
                        string,
                      ]
                    )
                  }}
                >
                  {item.label}
                </Tag>
              ))}
            </Space>
          )}
        />
      </>
    )
  }
)
CustomRangePicker.displayName = "自定义RangePicker"
// export default CustomRangePicker
