import { BadgeProps } from "antd"

// 文件类型
export const taxRegistrationStatusEnum = [
  {
    value: "IMAGE",
    label: "图片",
  },
  {
    value: "FILE",
    label: "文档",
  },
]
//是否必填
export const requiredEnum = [
  {
    value: true,
    label: "是",
  },
  {
    value: false,
    label: "否",
  },
]
//是否必填
export const dualPrefixEnum = [
  {
    value: "newGeneral1",
    label: "新再生网",
  },
  {
    value: "lvnewGeneral",
    label: "绿易网",
  },
]
export const dualPrefixShopEnum = [
  {
    value: "newGeneral1",
    label: "新再生网",
  },
  {
    value: "newGeneral2",
    label: "供销再生",
  },
]
export const dualPrefixEnumDetails = {
  newGeneral1: "新再生网",
  lvnewGeneral: "绿易网",
  newGeneral2: "供销再生",
}
export const siteShowTypeEnum = {
  SAIKUL: "新再生网",
  LY: "绿易网",
  GX: "供销再生",
  JL: "建龙",
}

export const prodListEnum: any = [
  {
    value: "SAIKUL",
    label: "新再生网：https://www.saikul.com/newseller/序号",
  },
  {
    value: "LY",
    label: "绿易网：https://www.lvyinet.com/序号",
  },
]
export const prodListJoinIdEnum: any = {
  SAIKUL: "新再生网：https://www.saikul.com/newseller/",
  LY: "绿易网：https://www.lvyinet.com/",
  GX: "供销再生网：https://www.nbcoop.com.cn/序号",
}
export const shopListEnum: any = [
  {
    value: "GX",
    label: "供销再生网：https://www.nbcoop.com.cn/序号",
  },
]
export const jianLongListEnum: any = [
  {
    value: "JL",
    label: "建龙再生网：https://www.nbcoop.com.cn/序号",
  },
]
export const handProcess = () => {
  let _processList: any = []
  switch (process.env.REACT_APP_ENV) {
    case "production":
      _processList.push(...prodListEnum)
      break
    case "test":
      _processList.push(...prodListEnum)
      break
    case "development":
      _processList.push(...prodListEnum)
      break
    case "shop":
      _processList.push(...shopListEnum)
      break
    case "jianlong":
      _processList.push(...jianLongListEnum)
      break
    default:
      break
  }
  return _processList
}

export const TaxStatusEnum: Record<string, BadgeProps> = {
  0: {
    status: "error",
    text: "失效",
  },
  1: {
    status: "success",
    text: "有效",
  },
}

export const TaxStatusBooleanEnum: Record<string, BadgeProps> = {
  false: {
    status: "error",
    text: "失效",
  },
  true: {
    status: "success",
    text: "有效",
  },
}
