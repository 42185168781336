import _ from "lodash"
import { CommonStatus } from "@src/components/common"
import React from "react"
import { ISSUE_CHANNEL } from "./config"
import { Badge } from "antd"

export const taskStatusNameMap: any = {
  PENDING: {
    status: "warning",
    text: "待处理",
  },
  COMPLETED: {
    status: "success",
    text: "已完成",
  },
}
export default [
  {
    title: "任务编号",
    dataIndex: "taskId",
  },
  // {
  //     title: '来源',
  //     dataIndex: '',
  // },
  {
    title: "开票方",
    dataIndex: "taxpayerName",
  },
  {
    title: "纳税人识别号",
    dataIndex: "taxpayerNo",
  },
  {
    title: "所属税区",
    dataIndex: "taxRegistrationName",
  },
  {
    title: "办税人",
    dataIndex: "taxClerk",
  },
  {
    title: "状态",
    dataIndex: "taskStatus",
    render: (value: string, item: any) => {
      return value ? <Badge {...taskStatusNameMap[value]} /> : "-"
    },
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
  },
  {
    title: "完成时间",
    dataIndex: "completeTime",
  },
  {
    title: "开票渠道",
    dataIndex: "issueChannel",
    width: 150,
    render: (_: any, record?: any) => {
      return <span>{ISSUE_CHANNEL[_]}</span>
    },
  },
]
