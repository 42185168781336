/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react"
import { Button, DatePicker, Space, Input, Pagination, Select, Table } from "antd"
import { useNavigate } from "react-router-dom"
import { useSetState } from "ahooks"
import CommonSearch, { FormItem } from "@src/components/common/CommonSearch"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import Complete from "./complete"
import { getInvoicingTasksList } from "../promise"
import "./index.scss"
import { CustomRangePicker } from "../../../../components/CustomRangePicker"
import columnsP from "./columns"
import schema from "./schema"
import SKTable from "@/components/SkTable"
import useTableTitle from "@/hooks/useTableTitle"
import { getRangeDateOnUnitOfTime } from "../../../../utils/date/utils"
import { getOperRegions } from "../../../BusinessAgent/service"

const OpenTask = () => {
  const nav = useNavigate()
  const { setTotal, tableTitle } = useTableTitle("开通任务列表")
  const tableRef = useRef<any>(null)

  let [state, setState] = useSetState<any>({
    columnsData: [],
    taxRegion: [],
    total: 0,
    current: 1,
    size: 10,
    isRefresh: false,
    searchContent: {},
    isModal: false,
    id: "",
  })
  const init = async () => {
    const res: any = await getOperRegions({ current: 1, size: 999 })
    if (res?.data?.records.length) {
      let options = res.data.records.map((ie) => {
        return {
          value: ie.id,
          label: ie.name,
        }
      })
      tableRef.current.setSearch("taxRegistrationId", {
        props: {
          options,
        },
      })
    }
  }
  const columns: any = [
    ...columnsP,
    {
      title: "操作",
      dataIndex: "operation",
      fixed: "right",
      render: (_: any, item: any) => {
        return (
          <div className="operation">
            {item.taskStatus == "PENDING" && (
              <a
                onClick={() => {
                  setState({ isModal: true, id: item.id })
                }}
              >
                办理完成
              </a>
            )}
          </div>
        )
      },
    },
  ]

  const handleRequestData = async (params) => {
    const {
      current,
      pageSize,
      completeTimeBegin,
      completeTimeEnd,
      createTimeBegin,
      createTimeEnd,
      taxRegistrationId,
      ...rest
    } = params
    let _params: any = {
      size: pageSize,
      current: current,
      ...getRangeDateOnUnitOfTime({
        createTimeBegin: createTimeBegin,
        createTimeEnd: createTimeEnd,
      }),
      ...getRangeDateOnUnitOfTime({
        completeTimeBegin: completeTimeBegin,
        completeTimeEnd: completeTimeEnd,
      }),
      ...rest,
      taxClerk: rest?.taxClerk?.trim(),
      query: rest?.query?.trim(),
      taxRegistrationId: taxRegistrationId,
    }
    const result: any = await getInvoicingTasksList(_params)
    if (result?.code == 200) {
      setState({ columnsData: result.data.records, total: result.data.total })
      setTotal(result?.data.total)
    }
    return {
      data: result?.data?.records,
      total: result?.data.total,
    }
  }
  useEffect(() => {
    init()
  }, [])
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>开通任务</span>
          </Space>
        }
        breadCrumb={[
          { label: "税票系统", path: "" },
          { label: "开通任务", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <SKTable
          title={tableTitle}
          ref={tableRef}
          pagination={{
            pageSize: 10,
            showQuickJumper: true,
          }}
          // rowSelection={rowSelection}
          request={handleRequestData}
          pageChangeWithRequest={true}
          columns={columns}
          searchFlag={true}
          search={{
            mode: "simple",
            schema: schema,
          }}
        ></SKTable>
        {state.isModal && (
          <Complete
            cancelModal={(isRefresh?: boolean) => {
              if (isRefresh) {
                setState({ isRefresh: !state.isRefresh })
              }
              setState({ isModal: false, id: "" })
            }}
            id={state.id}
          />
        )}
      </DetailPageContent>
    </DetailPageContainer>
  )
}

export default OpenTask
