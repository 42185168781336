/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Button, Space, Modal, Checkbox, Row, Col, message, Dropdown } from "antd"
import { useNavigate } from "react-router-dom"
import { useSetState } from "ahooks"
import SKTable from "@/components/SkTable"
import useTableTitle from "@/hooks/useTableTitle"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import ExportStoreList from "@src/components/export/storeList"
import { SettingOutlined } from "@ant-design/icons"
import moment from "moment"
import { exportDownloadExcel, getInvoicesList, getInvoicesPreviewList } from "../promise"
import columnsP from "./columns"
import "./index.scss"
import schema from "./schema"
import { getRangeDateOnUnitOfTime } from "../../../../utils/date/utils"
import { getOperRegions } from "../../../BusinessAgent/service"
const Ledger = () => {
  const nav = useNavigate()
  const { setTotal, tableTitle } = useTableTitle("发票台账列表")
  const [statList, setStatList] = useState({})
  const [exportReq, setExportReq] = useState({})
  let [state, setState] = useSetState<any>({
    columnsData: [],
    taxRegion: [],
    total: 0,
    current: 1,
    size: 10,
    isRefresh: false,
    searchContent: {},
    isExport: false,
    map: {},
  })
  const [modalVisible, setModalVisible] = useState(false)
  const [columnsList, setColumnsList] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [dataEdRowKeys, setDataEdRowKeys] = useState<React.Key[]>([])
  const tableRef = useRef<any>(null)

  const init = async () => {
    const res: any = await getOperRegions({ current: 1, size: 999 })
    if (res?.data?.records.length) {
      let options = res.data.records.map((ie) => {
        return {
          value: ie.id,
          label: ie.name,
        }
      })
      tableRef.current.setSearch("taxRegistrationId", {
        props: {
          options,
        },
      })
    }
  }
  const columns: any = [
    ...columnsP,
    {
      title: "操作",
      dataIndex: "operation",
      fixed: "right",
      render: (_: any, item: any) => {
        return (
          <div className="operation">
            <a
              onClick={() => {
                preview(item.id)
              }}
            >
              预览
            </a>
          </div>
        )
      },
    },
  ]

  const [columnsState, setColumnsState] = useState(() => {
    const initialState = {}
    columns.forEach((column) => {
      initialState[column.dataIndex] = true // 默认所有列都显示
    })
    return initialState
  })
  const onSelectChange = (newSelectedRowKeys: React.Key[], value) => {
    let _newSelectedRowKeys = value.map((ie) => ie?.orderNo)
    setSelectedRowKeys(newSelectedRowKeys)
    setDataEdRowKeys(_newSelectedRowKeys)
  }
  const handleRequestData = async (params: any) => {
    const { current, pageSize, invoiceDateStartDate, invoiceDateEndDate, ...rest } = params
    setStatList(params)
    if (params?.invoiceDateEndDate) {
      const reqParams = {
        ...params,
        invoiceDateStartDate: params?.invoiceDateStartDate + " 00:00:00",
        invoiceDateEndDate: params?.invoiceDateEndDate + " 23:59:59",
      }
      setExportReq(reqParams)
    } else {
      setExportReq(params)
    }

    const res: any = await getInvoicesList({
      current,
      size: pageSize,
      ...getRangeDateOnUnitOfTime({
        invoiceDateStartDate: invoiceDateStartDate,
        invoiceDateEndDate: invoiceDateEndDate,
      }),
      ...rest,
      keywords: params?.keywords?.trim(),
    })
    setTotal(res?.data.total)
    setState({
      columnsData: res.data.records,
      total: res.data.total,
      map: res.data.map,
      current: current,
      size: pageSize,
    })
    return {
      data: res?.data?.records,
      total: res?.data.total,
    }
  }
  const preview = async (id: string) => {
    const res: any = await getInvoicesPreviewList({ id })
    if (res.code == 200) {
      window.open(res.data)
    }
  }
  const handleChange = (columnName) => {
    setColumnsState({
      ...columnsState,
      [columnName]: !columnsState[columnName],
    })
  }
  const applyColumnSettings = () => {
    const filteredColumns = columns?.filter((column) => columnsState[column?.dataIndex])
    setColumnsList(filteredColumns)
  }
  const handleOk = () => {
    setModalVisible(false)
    applyColumnSettings()
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  useEffect(() => {
    applyColumnSettings()
  }, [])
  useEffect(() => {
    init()
  }, [])
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>发票台账</span>
          </Space>
        }
        breadCrumb={[
          { label: "税票系统", path: "" },
          { label: "发票台账", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <div className="ledger">
          <SKTable
            title={tableTitle}
            ref={tableRef}
            toolbarRender={
              <>
                <Button
                  className="func_item"
                  type="primary"
                  onClick={() => {
                    setState({ isExport: true })
                  }}
                >
                  导出明细
                </Button>
                <Button
                  style={{ marginRight: 10 }}
                  onClick={async () => {
                    let params = {
                      orderNos: dataEdRowKeys.join(","),
                      formatType: "PDF",
                    }
                    if (selectedRowKeys.length > 0) {
                      await exportDownloadExcel(params)
                    } else {
                      message.error("至少选择一条数据")
                    }
                  }}
                >
                  导出发票
                </Button>

                <SettingOutlined onClick={() => setModalVisible(true)} />
                <div className="list-summary-information" style={{ marginBottom: 10 }}>
                  <span>不含税开票额：{state.map?.amountWithoutTax?.toLocaleString() + " 元"}</span>
                  <span>合计税额：{state.map?.taxAmount?.toLocaleString() + " 元"}</span>
                  <span>价税合计：{state.map?.amountWithTax?.toLocaleString() + " 元"}</span>
                  <p>合计数量：{state.map?.itemQuantityStatistics}</p>
                </div>
              </>
            }
            pagination={{
              pageSize: 10,
              showQuickJumper: true,
            }}
            rowSelection={rowSelection}
            request={handleRequestData}
            pageChangeWithRequest={true}
            columns={columnsList}
            searchFlag={true}
            search={{
              mode: "simple",
              schema: schema,
            }}
          ></SKTable>
        </div>
        {state.isExport && (
          <ExportStoreList
            columnsKey={() => {
              let keys = [...columns]
              keys.pop()
              console.log(keys, "keys")
              return keys
            }}
            url={`/rscm/rscm-invoice/invoices/export`}
            exportName={`发票台账_${moment().format("YYYY-MM-DD")}.xlsx`}
            current={state.current}
            size={state.size}
            total={state.total}
            serchObj={exportReq}
            headers={{ baseUrl: "SP" }}
            closeExportStoreList={() => {
              setState({ isExport: false })
            }}
          />
        )}
        <Modal
          title="自定义显示字段"
          visible={modalVisible}
          footer={null}
          onCancel={() => setModalVisible(false)}
        >
          <div className="ledger-checkbox">
            <Row gutter={[16, 16]}>
              {columns.map((column) => (
                <Col span={6} key={column.dataIndex}>
                  <Checkbox
                    checked={columnsState[column.dataIndex]}
                    onChange={() => handleChange(column.dataIndex)}
                  >
                    {column.title}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </div>

          <div
            style={{
              padding: 10,
              borderTop: "1px solid #f0f0f0",
            }}
          >
            <div style={{ marginLeft: "70%" }}>
              <Button style={{ marginRight: "12px" }} onClick={() => setModalVisible(false)}>
                取消
              </Button>
              <Button type="primary" onClick={handleOk}>
                确定
              </Button>
            </div>
          </div>
        </Modal>
      </DetailPageContent>
    </DetailPageContainer>
  )
}

export default Ledger
