import { ProDescriptionsItemProps } from "@ant-design/pro-descriptions"
import { RexALEmail } from "@src/constant/RegExp"
import { RexChinesePhone } from "@src/utils/RegExp"
import { PresetStatusColorType } from "antd/es/_util/colors"

export const EntityTypeEnum = {
  INDIVIDUAL: "自然人",
  INDIVIDUAL_BUSINESS: "个体户",
  ENTERPRISE: "企业",
}
export const SourceTypeEnum = {
  EP: "EP",
  CRM: "CRM",
  CONSIGNORS: "货主端",
  AGENCY: "个体户代办",
}

export const IndividualBusinessStatusEnum = new Map<
  number,
  { status: PresetStatusColorType; text: string }
>([
  [
    -2,
    {
      text: "已注销",
      status: "default",
    },
  ],
  [
    -1,
    {
      text: "申请注销",
      status: "warning",
    },
  ],
  [
    0,
    {
      text: "禁用",
      status: "error",
    },
  ],
  [
    1,
    {
      text: "正常",
      status: "success",
    },
  ],
])

export const EntityStatusEnum = new Map([
  [0, { text: "禁用", status: "Error" }],
  [1, { text: "正常", status: "Success" }],
])
export const invoiceSystemStatusEnum = new Map([
  ["NO", { text: "未开通", status: "warning" }],
  ["YES", { text: "已开通", status: "success" }],
])
export const TaxPaymentCycleEnum = new Map([
  ["MONTH", { text: "按月缴税" }],
  ["QUARTER", { text: "按季度缴税" }],
])
export const commonHeaderDetail: ProDescriptionsItemProps<API.Entity.Info>[] = [
  {
    dataIndex: "sourceType",
    title: "来源类型",
    editable: false,
    valueEnum: SourceTypeEnum,
  },
  { dataIndex: "createUserName", title: "创建人", editable: false },
  {
    dataIndex: "type",
    title: "实体类型",
    editable: false,
    valueEnum: EntityTypeEnum,
  },
  { dataIndex: "sourceName", title: "来源名称", editable: false },
  { dataIndex: "createUserAccount", title: "创建账号", editable: false },
]

export const invoiceInfoColumns: ProDescriptionsItemProps<API.Entity.Info>[] = [
  { dataIndex: "invoicingBankName", title: "开票行名称" },
  { dataIndex: "invoicingBankNo", title: "开票行账号" },
  { dataIndex: "invoicingBankAddress", title: "开票地址" },
  {
    dataIndex: "invoicingPhone",
    title: "开票行电话",
    formItemProps: { rules: [{ message: "请填写正确格式", pattern: RexChinesePhone }] },
  },
  {
    dataIndex: "invoicingEmail",
    title: "发票邮箱",
    formItemProps: { rules: [{ message: "请填写正确格式", pattern: RexALEmail }] },
  },
]

export const getMenuBtnList = (menuTreeList, menuList) => {
  for (let i = 0; i < menuTreeList.length; i++) {
    menuList.push(menuTreeList[i]?.id)
    if (menuTreeList[i]?.children?.length > 0) {
      getMenuBtnList(menuTreeList[i]?.children, menuList)
    }
  }
}
export const couponEnm = [
  {
    value: "PHONE_RECOVERY_A",
    label: "特密",
  },
  {
    value: "PHONE_RECOVERY_B",
    label: "高级",
  },
  {
    value: "PHONE_RECOVERY_C",
    label: "普通",
  },
]
export const couponEnmList = {
  PHONE_RECOVERY_A: "特密",
  PHONE_RECOVERY_B: "高级",
  PHONE_RECOVERY_C: "普通",
}
