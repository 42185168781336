import icon01 from "@src/assets/sidebar/01.png"
import icon02 from "@src/assets/sidebar/02.png"
import icon03 from "@src/assets/sidebar/03.png"
import icon04 from "@src/assets/sidebar/04.png"
import icon05 from "@src/assets/sidebar/05.png"
import icon06 from "@src/assets/sidebar/06.png"
import icon07 from "@src/assets/sidebar/07.png"
import icon08 from "@src/assets/sidebar/08.png"
import icon09 from "@src/assets/sidebar/09.png"
import icon10 from "@src/assets/sidebar/10.png"
import icon11 from "@src/assets/sidebar/11.png"
import icon12 from "@src/assets/sidebar/12.png"
import icon13 from "@src/assets/sidebar/13.png"
import icon14 from "@src/assets/sidebar/14.png"
import icon15 from "@src/assets/sidebar/15.png"
import icon16 from "@src/assets/sidebar/16.png"
import icon17 from "@src/assets/sidebar/17.png"
import icon18 from "@src/assets/sidebar/18.png"
import icon19 from "@src/assets/sidebar/19.png"
import icon20 from "@src/assets/sidebar/20.png"
import supply from "@src/assets/supply.png"

interface NavObj {
  path: string //菜单路由
  name: string //菜单名称
  icon: string //菜单图标
  code: string //菜单编码
  id: string //菜单id
  parentid: string | null //菜单父id
  children?: NavObj[] //子菜单
  meta?: {
    microRouteName: string
  }
}
export const navConfig: Menu.MenuOptions[] | any = [
  {
    name: "数据统计",
    path: "/data/chart",
    value: "data",
    code: "op_data",
    key: "data",
    parentid: "4",
    children: [
      {
        name: "数据看板",
        path: "/data/chart",
        icon: icon01,
        code: "op_data_chart",
        key: "data_chart",
        parentid: "4-1",
      },
      {
        name: "数据大屏",
        path: "/data/large",
        icon: icon02,
        code: "op_data_large",
        key: "data_large",
        parentid: "4-2",
      },
    ],
  },
  {
    name: "交易中心",
    path: "/entity/transaction/platformClassification",
    value: "entity",
    code: "op_entity",
    key: "entity",
    parentid: "1",
    children: [
      {
        name: "平台品类",
        icon: icon03,
        path: "/entity/transaction/platformClassification",
        code: "op_entity_transaction_platformClassification",
        key: "entity_transaction_platformClassification",
        parentid: "1-1",
      },
      {
        name: "交易方案",
        icon: icon04,
        path: "/entity/transaction/tradingScheme",
        code: "op_entity_tradingScheme",
        key: "entity_transaction_tradingScheme",
        parentid: "1-2",
      },
      {
        name: "合同模板管理",
        icon: icon05,
        path: "/entity/transaction/contractTemplate",
        code: "op_entity_transaction_contractList",
        key: "entity_transaction_contractList",
        parentid: "1-3",
      },
      {
        name: "订单管理",
        path: "/entity/transaction/procureOrder",
        code: "op_entity_order",
        key: "entity_order",
        parentid: "1-1",
        icon: icon06,
        children: [
          {
            name: "采购订单",
            code: "op_entity_order_purchase",
            key: "entity_order_purchase",
            parentid: "1-1-1",
            path: "/entity/transaction/procureOrder",
          },
        ],
      },
      {
        name: "优惠券发放",
        icon: icon03,
        path: "/entity/transaction/coupon",
        code: "op_entity_transaction_coupon",
        key: "entity_transaction_coupon",
        parentid: "1-5",
      },
    ],
  },
  {
    name: "平台设置",
    path: "/platformSetup/user",
    value: "platformSetup",
    code: "op_platform",
    key: "platform",
    parentid: "1",
    children: [
      {
        name: "用户管理",
        path: "/platformSetup/user",
        code: "op_platform_children_user",
        icon: icon07,
        key: "platform_children-user",
        parentid: "1-1",
      },

      {
        name: "应用管理",
        path: "/platformSetup/apply",
        code: "op_platform_children_apply",
        icon: icon08,
        key: "platform_children_apply",
        parentid: "2-1",
      },

      {
        name: "组织管理",
        path: "/platformSetup/tissue",
        code: "op_platform_children_tissue",
        icon: icon09,
        key: "platform_children_tissue",
        parentid: "3-1",
      },
      {
        name: "入驻审核",
        path: "/platformSetup/check",
        code: "op_platform_check",
        icon: icon07,
        key: "platform_check",
        parentid: "8-1",
      },
      {
        name: "公众号",
        path: "/platformSetup/publicAccount",
        code: "op_platform_publicAccount",
        key: "platform_publicAccount",
        parentid: "9-1",
        icon: icon07,
        children: [
          {
            name: "二维码",
            code: "op_platform_publicAccount_twoCode",
            key: "platform_publicAccount_twoCode",
            parentid: "9-1-2",
            path: "/platformSetup/publicAccount/twoCode",
          },
        ],
      },
      {
        name: "税区管理",
        path: "/platformSetup/district",
        code: "op_platform_children_district",
        icon: icon10,
        key: "platform_children_district",
        parentid: "4-1",
      },
      {
        name: "开票名称配置",
        path: "/platformSetup/invoice",
        code: "op_platform_children_invoice",
        icon: icon11,
        key: "platform_children_invoice",
        parentid: "5-1",
      },
      {
        name: "同步新再生网订单",
        path: "/platformSetup/utilOrder",
        code: "op_platform_children_utilOrder",
        icon: supply,
        key: "platform_children_utilOrder",
        parentid: "6-1",
      },
    ],
  },
  {
    name: "代办业务",
    path: "/agencyBusiness/setup",
    value: "agencyBusiness",
    code: "op_businessAgent",
    // key: "platform",
    key: "agencyBusiness",
    parentid: "1",
    children: [
      {
        name: "工商税务开办",
        path: "/agencyBusiness/setup",
        code: "op_agencyBusiness",
        key: "platform_user",
        // key: "agencyBusiness-setup",
        parentid: "1-1",
        icon: icon12,
        children: [
          {
            name: "个体户开办申请",
            path: "/agencyBusiness/setup",
            code: "op_agencyBusiness_setup",
            // key: "platform_children-user",
            key: "agencyBusiness_children-setup",
            parentid: "1-1-2",
          },
          {
            name: "个体户注销申请",
            path: "/agencyBusiness/OutSetup",
            code: "op_agencyBusiness_outSetup",
            // key: "platform_children-user",
            key: "agencyBusiness_children-OutSetup",
            parentid: "1-1-3",
          },
        ],
      },
    ],
  },
  {
    name: "税票系统",
    path: "/ticket/openTask",
    code: "op_openTask",
    // key: "openTask",
    value: "ticketOpenTask",
    key: "ticket",
    parentid: "1",
    children: [
      {
        name: "开票",
        path: "/ticket/openTask",
        code: "op_ticket",
        key: "op_ticket",
        parentid: "1-1",
        icon: icon13,
        children: [
          {
            name: "开通任务",
            path: "/ticket/openTask",
            code: "op_ticket_openTask",
            key: "op—ticket-openTask",
            parentid: "1-1-1",
          },
          {
            name: "开票方列表",
            path: "/ticket/invoicingParty",
            code: "op_ticket_invoicingParty",
            key: "op—ticket-invoicingParty",
            parentid: "1-1-2",
          },
          {
            name: "开票任务",
            path: "/ticket/InvoicingTask",
            code: "op_ticket_InvoicingTask",
            key: "op—ticket-InvoicingTask",
            parentid: "1-2-3",
          },
          {
            name: "发票台账",
            path: "/ticket/ledger",
            code: "op_ticket_ledger",
            key: "op—ticket-ledger",
            parentid: "1-3-4",
          },
        ],
      },
    ],
  },
  {
    name: "存证平台",
    path: "/evidence/platform/template",
    code: "op_evidence",
    value: "evidence",
    key: "evidence",
    parentid: "1",
    children: [
      {
        name: "平台配置",
        path: "/evidence/platform/template",
        code: "op_evidence_set",
        key: "op_evidence_set",
        parentid: "15",
        icon: icon14,
        children: [
          {
            name: "存证场景模板",
            code: "op_evidence_set_scene",
            key: "op_evidence_set_scene",
            path: "/evidence/platform/template",
            icon: supply,
            parentid: "15-1",
          },
          {
            name: "存证字段维护",
            code: "op_evidence_field",
            key: "op_evidence_field",
            path: "/evidence/platform/field",
            icon: supply,
            parentid: "15-2",
          },
        ],
      },
    ],
  },
  {
    name: "物联管理",
    path: "/internet",
    code: "op_internet",
    value: "internet",
    key: "internet",
    parentid: "1",
    children: [
      {
        name: "监控管理",
        path: "/internet",
        code: "op_internet_things",
        key: "op_internet_things",
        parentid: "15",
        icon: icon15,
      },
    ],
  },
  {
    name: "供应链金融",
    path: "/finance",
    code: "op_finance",
    value: "finance",
    key: "finance",
    parentid: "1",
    children: [
      {
        name: "融资订单",
        path: "/finance/order",
        code: "op_finance_financing",
        key: "op_finance_financing",
        parentid: "15",
        icon: icon16,
      },
      {
        name: "核心企业准入",
        path: "/finance/enterprise",
        code: "op_finance_enterprise",
        key: "op_finance_enterprise",
        parentid: "15",
        icon: icon17,
      },
      {
        name: "供应商准入",
        path: "/finance/consent",
        code: "op_finance_consent",
        key: "op_finance_consent",
        parentid: "15",
        icon: icon18,
      },
      {
        name: "融资产品",
        path: "/finance/product",
        code: "op_finance_product",
        key: "op_finance_product",
        parentid: "15",
        icon: icon19,
      },
    ],
  },
  {
    name: "物流管理",
    path: "/logistics",
    code: "op_logistics",
    value: "logistics",
    key: "logistics",
    parentid: "1",
    children: [
      {
        name: "云仓管理",
        path: "/logistics/warehouse",
        code: "op_logistics_warehouse",
        key: "op_logistics_warehouse",
        parentid: "49",
        icon: icon20,
      },
      {
        name: "物流轨迹",
        path: "/logistics/trajectory",
        code: "op_logistics_trajectory",
        key: "op_logistics_trajectory",
        parentid: "50",
        icon: icon20,
      },
    ],
  },
]

export const deepLoopFloat = (menuList: Menu.MenuOptions[], codeList: string[]) => {
  const arr: Menu.MenuOptions[] = []
  menuList.forEach((item: Menu.MenuOptions) => {
    const obj: Menu.MenuOptions = {
      name: "",
      path: "",
      code: "",
      value: "",
      key: "",
    }
    if (codeList && codeList?.indexOf(item?.code) > -1) {
      obj.path = item?.path
      obj.value = item?.value
      obj.name = item?.name
      obj.key = item?.key
      obj.icon = item?.icon
      obj.code = item?.code
      obj.id = item?.id
      obj.parentid = item.parentid

      if (item?.children?.length) {
        obj.children = deepLoopFloat(item.children, codeList)
      }
      arr.push(obj)
    }
  })
  return arr
}
