import { OP_USER } from "@src/api/config/servicePort"
import UtilOp from "@src/services/serviceOp"
import { OP_SOURCE } from "../../../../api/config/servicePort"

const utilOp = UtilOp.getInstance()
// 二维码列表
export const getMpQrCodeList = async (params: any) => {
  return utilOp.httpGet(`${OP_USER}/mp-qrcode`, params).then((res) => res.data)
}
// 修改二维码
export const putCode = async (params: any) => {
  return utilOp.httpPut(`${OP_USER}/mp-qrcode/${params.id}`, params).then((res) => res)
}
// 创建二维码
export const postCode = async (params: any) => {
  return utilOp.httpPost(`${OP_USER}/mp-qrcode`, params).then((res) => res)
}

export const delMpQrCode = async (id: any) => {
  return utilOp.httpDeletel(`${OP_USER}/mp-qrcode/${id}`).then((res) => res)
}

//id换取文件路径
export const getUploadByPath = async (params: any) => {
  return utilOp.httpPatch(OP_SOURCE + `/oss/endpoint`, params)
}
